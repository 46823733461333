import { useTranslation } from 'react-i18next';
import { FAQS } from '../helper/constant'

function Faq() {
    const { t } = useTranslation();

    return (
        <>
            <div className="presale-section" id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-smp-12 col-sm-12 col-lg-12">
                            <div className='text-center'>
                                <h2 className='section-title mb-5 text-yellow'>{t('faqSection.title')}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='align-items-center row d-flex justify-content-center'>
                        <div className="col-sm-12 col-md-8 col-lg-8 mt-5 mt-md-0">
                            <div className="faq-accordion" id="accordionExample">
                                {FAQS && FAQS.map((item, key) => (
                                    <div className="card" key={key}>
                                        <div className="card-header">
                                            <h5 className="mb-2">
                                                <a href="#sec" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#collapse-${key}`} aria-expanded="true" aria-controls={`collapse-${key}`}>
                                                    <span>{t(item.question)}</span>
                                                    <ins></ins>
                                                </a>
                                            </h5>
                                        </div>
                                        <div id={`collapse-${key}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="card-body" dangerouslySetInnerHTML={{ __html: t(item.answer) }}></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-smp-12 col-sm-12 col-lg-12">
                            <div className='text-center'>
                                <h2 className='section-title text-yellow'>{t('faqSection.socialMediaTitle')}</h2>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <a className="mt-2 mx-2" href="https://x.com/clashofmemesai" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter icon fs-1"></i>
                                </a>
                                <a className="mt-2 mx-2" href="#sec" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram icon fs-1"></i>
                                </a>
                                <a className="mt-2 mx-2" href="#sec" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-telegram icon fs-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Faq