import React from 'react';
import bitMartImg from '../Assets/img/bitmart.png';
import xtImg from '../Assets/img/xt.png';
import lbankImg from '../Assets/img/lbank.png';

export default function MultipleExchange() {
    const images = [
        { src: bitMartImg, alt: 'bitmart' },
        { src: xtImg, alt: 'xt' },
        { src: lbankImg, alt: 'lbank' }
    ];

    return (
        <div className='presale-section'>
            <div className="container">
                <div class="row">
                    <div class="col-smp-12 col-sm-12 col-lg-12">
                        <div class="text-center">
                            <h2 class="section-title mb-5 text-yellow">$CLMAI IS SET TO LAUNCH ON MULTIPLE EXCHANGES</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {images.map((image, index) => (
                        <div className="col-12 col-sm-4 mb-3" key={index}>
                            <div className="card exchange-card">
                                <div className="card-body d-flex justify-content-center">
                                    <img className="img-fluid" src={image.src} alt={image.alt} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
