import ClipLoader from "react-spinners/ClipLoader";

function Loading() {
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "#37b9fc",
      };

    return (
        <div className="loading-overlay">
            <ClipLoader
                color="#fff"
                loading={true}
                cssOverride={override}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
}

export default Loading;