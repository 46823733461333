import { TOKEN_SYMBOL, presaleData, presaleTotalData } from '../helper/constant';


function Presale() {
    return (
        <>
            <div className="presale-section">
                <div className="container">
                    <div className="row">
                        <div className="col-smp-12 col-sm-12 col-lg-12">
                            <div className='text-center'>
                                <h2 className='section-title mb-5 text-yellow'>{TOKEN_SYMBOL} PRESALE STAGES</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-9">
                            <div className="table-responsive">
                                <table className="table table-dark table-hover text-center table-bordered" aria-label="simple table">
                                    <thead className="">
                                        <tr >
                                            <th>Round</th>
                                            <th>Supply</th>
                                            <th>Price</th>
                                            <th>Total in round</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {presaleData.map((data, index) => (
                                            index === 2 ? (
                                                <tr key={index}>
                                                    <th colSpan={4} className='text-yellow'>{data.round} ~ {data.price}</th>
                                                </tr>
                                            ) : (
                                                <tr key={index}>
                                                    <th>{data.round}</th>
                                                    <td>{data.supply}</td>
                                                    <td>{data.price}</td>
                                                    <td>{data.total}</td>
                                                </tr>
                                            )
                                        ))}
                                        <tr>
                                            <th>{presaleTotalData.round}</th>
                                            <th>{presaleTotalData.supply}</th>
                                            <th>{presaleTotalData.price}</th>
                                            <th>{presaleTotalData.total}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Presale;