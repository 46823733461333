import React from 'react';
import Slider from "react-slick";
import g1Img from '../Assets/img/g1.jpg'
import g2Img from '../Assets/img/g2.jpg'
import g3Img from '../Assets/img/g3.jpg'
import g4Img from '../Assets/img/g4.jpg'
import g5Img from '../Assets/img/g5.jpg'
import g6Img from '../Assets/img/g6.jpg'
import g7Img from '../Assets/img/g7.gif'
import g8Img from '../Assets/img/g8.gif'
import g9Img from '../Assets/img/g9.gif'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function Gallery() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div class="container">
            <div id="gallery" class="gallery-wrap m-auto mb-5">
                <h3 class="title text-uppercase mb-4 text-yellow">AI RUMBLE GALLERY</h3>
                <div class="gallery d-none d-md-flex px-4">
                    <img alt="galaryimage" src={g1Img} />
                    <img alt="galaryimage" src={g2Img} />
                    <img alt="galaryimage" src={g3Img} />
                    <img alt="galaryimage" src={g4Img} />
                    <img alt="galaryimage" src={g5Img} />
                    <img alt="galaryimage" src={g6Img} />
                    <img alt="galaryimage" src={g7Img} />
                    <img alt="galaryimage" src={g8Img} />
                    <img alt="galaryimage" src={g9Img} />
                </div>

                <div className="gallery-slider d-block d-md-none px-4">
                    <Slider {...settings}>
                        <div><img alt="galaryimage" src={g1Img} /></div>
                        <div><img alt="galaryimage" src={g2Img} /></div>
                        <div><img alt="galaryimage" src={g3Img} /></div>
                        <div><img alt="galaryimage" src={g4Img} /></div>
                        <div><img alt="galaryimage" src={g5Img} /></div>
                        <div><img alt="galaryimage" src={g6Img} /></div>
                        <div><img alt="galaryimage" src={g7Img} /></div>
                        <div><img alt="galaryimage" src={g8Img} /></div>
                        <div><img alt="galaryimage" src={g9Img} /></div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}
