import React from 'react'
import banImg from '../Assets/img/shield.png';

export default function PresaleNotActive() {
    return (
        <div className="loading-overlay">
            <div className="overlay-content">
                <img src={banImg} alt='presale-not-active' className='mb-4' height="120px" />
                <h2>Presale Not Active</h2>
                <p>The presale is currently not active. Please check back later.</p>
            </div>
        </div>
    )
}
