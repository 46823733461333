import { useEffect, useMemo, useRef, useState } from 'react';
import { useCommonStats } from '../hooks/useCommon';
import { ConnectButton } from './ConnectButton';
import { BUY_CURRENCY, DEFAULT_CHAIN, INITAL_RAISED, LISTING_PRICE, PRESALE_ADDRESS, TOKEN_NAME, TOKEN_SYMBOL, TOTAL_STAGE } from '../helper/constant';
import { formatPrice, getWeb3, trimAddress } from '../helper/functions';
import iconImg from '../Assets/img/logo.jpg';
import { useAccount, useNetwork } from 'wagmi';
import { getContract, getTokenAmount } from '../helper/contractHelper';
import Loading from './Loading';
import { useAccountStats } from '../hooks/useAccount';
import tokenAbi from '../abis/token.json';
import presaleAbi from '../abis/presale.json';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useLocation } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import PresaleNotActive from './PresaleNotActive';
import { useTranslation } from 'react-i18next';


function HeroSection() {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const [updator, setUpdator] = useState(1);
    const [loading, setLoading] = useState(false);
    const stats = useCommonStats(updator);
    const [selectedId, setSelectedId] = useState(0);
    const accStats = useAccountStats(updator, selectedId)
    const [amount, setAmount] = useState('')
    const [usdValue, setUsdValue] = useState(0);
    const selectedData = useMemo(() => {
        return BUY_CURRENCY && BUY_CURRENCY[selectedId] ? BUY_CURRENCY[selectedId] : {};
    }, [selectedId]);
    const [tokenAmount, setTokenAmount] = useState();
    const timeoutRef = useRef(null);
    const signer = useEthersSigner();
    const [refAddress, setRefAddress] = useState('');
    const search = useLocation().search;
    const [refcopy, setRefcopy] = useState(false);
    const [isClaimTab, setIsClaimTab] = useState(false);
    const { t } = useTranslation();




    useEffect(() => {
        const queryChainId = new URLSearchParams(search).get('ref');
        if (queryChainId !== '') {
            setRefAddress(queryChainId);
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetch() {
            let resAmount = BUY_CURRENCY && BUY_CURRENCY[selectedId] ? await getTokenAmount(BUY_CURRENCY[selectedId].address, amount, BUY_CURRENCY[selectedId].decimals) : 0
            setTokenAmount(resAmount[0])
            setUsdValue(resAmount[1])
        }
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId])


    const handleChangeAmount = async (e) => {
        let value = e.target.value;
        const patt = /^\d+\.{0,1}\d{0,6}$/;
        if (value === '' || value === '0') {
            setAmount(value);
            setTokenAmount(0)
        }
        else if (patt.test(value)) {
            setAmount(value);
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }


        timeoutRef.current = setTimeout(async () => {
            if (patt.test(value)) {
                let resAmount = BUY_CURRENCY && BUY_CURRENCY[selectedId] ? await getTokenAmount(BUY_CURRENCY[selectedId].address, value, BUY_CURRENCY[selectedId].decimals) : 0
                setTokenAmount(resAmount[0])
                setUsdValue(resAmount[1])
            }
        }, 1000);

    };

    const handleApprove = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);

                    let tokenContract = getContract(tokenAbi, BUY_CURRENCY[selectedId].address, signer);
                    let amount1 = ethers.utils.parseEther(amount.toString()).toString();

                    let tx = await tokenContract.approve(PRESALE_ADDRESS, amount1, { 'from': address });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.dismiss()
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handleSubmit = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);

                    let presaleContract = getContract(presaleAbi, PRESALE_ADDRESS, signer);
                    let amount1 = ethers.utils.parseEther(amount.toString()).toString();
                    let refAddr = refAddress ? refAddress : '0x0000000000000000000000000000000000000000';
                    let valuePass = BUY_CURRENCY[selectedId].address === '0x0000000000000000000000000000000000000000' ? amount1 : 0;
                    let tx = await presaleContract.buyToken(BUY_CURRENCY[selectedId].address, amount1, refAddr, { 'from': address, value: valuePass });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.dismiss()
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handleClaim = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);

                    let presaleContract = getContract(presaleAbi, PRESALE_ADDRESS, signer);
                    let tx = await presaleContract.claim({ 'from': address });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.dismiss()
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    return (
        <>
            <div className="banner-secetion" id="home">
                <div className="container">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="row align-items-center mb-5">
                                <div className="col-12 col-lg-12 mt-4 text-center">
                                    <h3 className="mt-1 text-yellow">{t('home.bannerTitle')}</h3>
                                    <p className="mb-1">{t('home.bannerSubtitle1')}</p>
                                    <p className="mb-1">{t('home.bannerSubtitle2')}</p>
                                    <p className="mb-1">{t('home.bannerSubtitle3')}</p>
                                    <p className="mb-1">{t('home.bannerSubtitle4')}</p>
                                    <p className="mb-1">{t('home.bannerSubtitle5')}</p>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="mt-5 ref-section">
                                        <h2 className="section-title mb-0">
                                            {t('home.referralProgram.title')}
                                        </h2>
                                        <p className="content-text mt-0">
                                            {t('home.referralProgram.description', {
                                                levelRate: stats.levelRate ? stats.levelRate / 100 : 0,
                                                tokenName: TOKEN_NAME
                                            })}
                                        </p>
                                        <div className="input-group copy-input mt-1">
                                            <input
                                                value={address ? `${window.location.href}?ref=${address}` : t('home.referralProgram.inputPlaceholder')}
                                                type="text"
                                                className="form-control"
                                                placeholder={t('home.referralProgram.inputPlaceholder')}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                            />
                                            <CopyToClipboard
                                                text={address ? `${window.location.href}?ref=${address}` : t('home.referralProgram.inputPlaceholder')}
                                                onCopy={() => {
                                                    setRefcopy(true);
                                                    setTimeout(() => {
                                                        setRefcopy(false);
                                                    }, 2000);
                                                }}
                                            >
                                                <button className="btn btn-cust" type="button" id="button-addon2">
                                                    {!refcopy ? (
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="24"
                                                            height="24"
                                                            stroke="#fff"
                                                            strokeWidth="2"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="css-i6dzq1"
                                                        >
                                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="24"
                                                            height="24"
                                                            stroke="#fff"
                                                            strokeWidth="2"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="css-i6dzq1"
                                                        >
                                                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                                            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                                        </svg>
                                                    )}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                        <p className='mt-4 font-bold text-yellow mb-2'>TAX - 0% </p>
                                        <p className="content-text  text-yellow mt-0 font-bold">
                                            {t('ref_below_text_1')},<br />
                                            {t('ref_below_text_2')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mt-md-0">
                            <div className="card cust-card">
                                {stats.loading ? (
                                    <Loading />
                                ) : !stats.saleStatus ? (
                                    <PresaleNotActive />
                                ) : (
                                    <></>
                                )}
                                {isClaimTab ? (
                                    <div className="card-body">
                                        <h5 className="mb-5 text-center">
                                            {t('home.presaleStatus.account', { address: address ? trimAddress(address) : t('home.presaleStatus.connectWallet') })}
                                        </h5>
                                        <div className="row">
                                            <div className="col-6 text-center">
                                                <h5 className="mb-3">{t('home.presaleStatus.totalTokens')}</h5>
                                                <p className="text-white">{accStats.purchasedOf ? formatPrice(accStats.purchasedOf, 10) : 0} {TOKEN_SYMBOL}</p>
                                            </div>
                                            <div className="col-6 text-center">
                                                <h5 className="mb-3">{t('home.presaleStatus.totalClaimedTokens')}</h5>
                                                <p className="text-white">{accStats.claimedOf ? formatPrice(accStats.claimedOf, 15) : 0} {TOKEN_SYMBOL}</p>
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    onClick={() => handleClaim()}
                                                    type="button"
                                                    disabled={loading || accStats.userAvalibleClaim <= 0}
                                                    className="btn tab-btn w-100"
                                                >
                                                    {loading ? t('home.presaleStatus.claimButton.loading') : t('home.presaleStatus.claimButton.default')}
                                                </button>
                                            </div>
                                            {/* {!stats.claimStatus && ( */}
                                            <a href="#sec" onClick={() => setIsClaimTab(false)} className="claim-btn mt-3">
                                                {t('home.presaleStatus.purchaseLink', { tokenSymbol: TOKEN_SYMBOL })}
                                            </a>
                                            {/* )} */}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <p className="card-title">{t('home.presaleInfo.title')}</p>
                                        <p className="amount-raised mt-2">
                                            {t('home.presaleInfo.amountRaised', {
                                                amountRaised: stats.stages && stats.stages[2] ? formatPrice(parseFloat(stats.stages[2] / Math.pow(10, 18)) + INITAL_RAISED, 5) : 0,
                                                totalAmount: stats.stages && stats.stages[3] ? formatPrice(parseFloat(stats.totalToken) * parseFloat(stats.stages[3] / Math.pow(10, 18))) : 0
                                            })}
                                        </p>
                                        <p className="text-center text-white" style={{ fontSize: "13px" }}>
                                            {t('home.presaleInfo.listingPrice', { listingPrice: LISTING_PRICE })}
                                        </p>
                                        <p className="card-subtitle">
                                            {t('home.presaleInfo.stageInfo', { currentStage: stats.liveStageId, totalStages: TOTAL_STAGE })}
                                        </p>
                                        <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow={stats.totalPer} aria-valuemin="0" aria-valuemax="100">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${stats.totalPer}%` }}>
                                                {stats.nextstage && stats.nextstage[5] && stats.nextstage[5] > 0 && (
                                                    <div className="">
                                                        {t('home.presaleInfo.nextPrice', { nextPrice: stats.nextstage && stats.nextstage[6] ? formatPrice(stats.nextstage[6] / Math.pow(10, 18)) : 0 })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="card-box">
                                            <div className="card-divider">
                                                <span className="card-divider-wrapper">
                                                    {t('home.presaleInfo.pricePerToken', { tokenSymbol: TOKEN_SYMBOL, tokenPrice: stats.stages && stats.stages[3] ? formatPrice(stats.stages[3] / Math.pow(10, 18)) : 0 })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="btn-group">
                                            {BUY_CURRENCY &&
                                                BUY_CURRENCY.length > 0 &&
                                                BUY_CURRENCY.map((items, key) => (
                                                    <button onClick={() => setSelectedId(key)} className={`btn ${selectedId === key ? 'tab-btn' : 'tab-btn2'}`} key={key} type="button">
                                                        <img src={items.image} height="24px" width="24px" alt={items.name} />
                                                        <span>{items.symbol}</span>
                                                    </button>
                                                ))}
                                        </div>
                                        <hr />
                                        <form className="cust-form">
                                            <div className="d-flex justify-content-between">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                                    {t('home.purchaseForm.enterAmount', { currencySymbol: selectedData?.symbol })}
                                                </label>
                                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                                    {t('home.purchaseForm.usdValue', { usdValue: formatPrice(usdValue) })}
                                                </label>
                                            </div>
                                            <div className="input-group">
                                                <input value={amount} onChange={(e) => handleChangeAmount(e)} type="text" className="form-control" placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                                <span className="input-group-text" id="basic-addon1">
                                                    <img src={selectedData?.image} height="30px" width="30px" alt="bnb" />
                                                </span>
                                            </div>
                                            {address && (
                                                <p className="my-0 text-white">
                                                    {t('home.purchaseForm.balance', { balance: accStats.tokenBal ? formatPrice(accStats.tokenBal, 5) : 0, currencySymbol: selectedData?.symbol })}
                                                </p>
                                            )}
                                            <label htmlFor="exampleFormControlInput1" className="form-label mt-3">
                                                {t('home.purchaseForm.youWillGet', { tokenSymbol: TOKEN_SYMBOL })}
                                            </label>
                                            <div className="input-group mb-4">
                                                <input value={tokenAmount} readOnly={true} type="text" className="form-control" placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                                <span className="input-group-text" id="basic-addon1">
                                                    <img src={iconImg} height="30px" width="30px" alt="logo" />
                                                </span>
                                            </div>
                                            <a href="#sec" onClick={() => setIsClaimTab(true)} className="claim-btn mb-2">
                                                {t('home.purchaseForm.claimLink', { tokenSymbol: TOKEN_SYMBOL })}
                                            </a>
                                            {address ? (
                                                parseFloat(accStats.isApproved) >= amount ? (
                                                    <button onClick={() => handleSubmit()} type="button" disabled={!stats.saleStatus || loading} className="btn tab-btn w-100">
                                                        {loading ? t('home.purchaseForm.buyButton.loading') : t('home.purchaseForm.buyButton.default')}
                                                    </button>
                                                ) : (
                                                    <button onClick={() => handleApprove()} type="button" disabled={!stats.saleStatus || loading} className="btn tab-btn w-100">
                                                        {loading ? t('home.purchaseForm.approveButton.loading') : t('home.purchaseForm.approveButton.default')}
                                                    </button>
                                                )
                                            ) : (
                                                <ConnectButton />
                                            )}
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-scroll-down active">
                    <a href="#how-buy" className="section-link">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
            </div>
        </>
    )
}
export default HeroSection;