import React from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_URL, TOKEN_SYMBOL } from '../helper/constant';

function Whitepaper() {
  const { t } = useTranslation();

  return (
    <>
      <div className="presale-section" id="whitepaper">
        <div className="container">
          <div className="row">
            <div className="col-smp-12 col-sm-12 col-lg-12">
              <div className='text-center'>
                <h2 className='section-title mb-5 text-yellow'>{t('whitepaperSection.title', { TOKEN_SYMBOL })}</h2>
              </div>
            </div>
          </div>
          <div className='align-items-center row'>
            <div className="col-sm-12 col-md-6 col-lg-6 text-center">
              <p>{t('whitepaperSection.description')}</p>
              <a href={`${BASE_URL}/WhitePaper.pdf`} target='_blank' rel="noreferrer" className='btn btn-primary me-3'>{t('whitepaperSection.downloadButton')}</a>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-5 mt-md-0">
              <div className="img-animate text-center">
                <img src={require('../Assets/img/whitepaper.gif')} className='img-fluid rounded-4' alt="Whitepaper" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Whitepaper;
