import { useTranslation } from 'react-i18next';
import { TOKEN_SYMBOL } from '../helper/constant';


function Buy() {
    const { t } = useTranslation();
    return (
        <>
            <div className="buy-section" id="how-buy">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h2 className='section-title mb-5 text-yellow'>
                                {t('howToBuy', { tokenSymbol: TOKEN_SYMBOL })}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="buy-card">
                                <div className="boder bd1">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd2">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd3">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd4">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="wrap-content mt-3">
                                    <div className="d-flex justify-content-start">
                                        <img src={require('../Assets/img/metamask.webp')} width={50} alt="Metamask" />
                                        <img src={require('../Assets/img/trustwallet.webp')} width={50} alt="Trust Wallet" />
                                    </div>
                                    <h3 className='step-title'>{t('step1Title')}</h3>
                                    <p>{t('step1Description')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="buy-card">
                                <div className="boder bd1">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd2">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd3">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd4">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="wrap-content mt-3">
                                    <div className="d-flex justify-content-start">
                                        <img src={require('../Assets/img/binance.webp')} width={50} alt="Binance" />
                                        <img src={require('../Assets/img/tether.webp')} width={50} alt="Tether" />
                                    </div>
                                    <h3 className='step-title'>{t('step2Title')}</h3>
                                    <p>{t('step2Description', { tokenSymbol: TOKEN_SYMBOL })}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="buy-card">
                                <div className="boder bd1">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd2">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd3">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="boder bd4">
                                    <i className="icon icon-stroke"></i>
                                </div>
                                <div className="wrap-content mt-3">
                                    <div className="d-flex justify-content-start">
                                        <img src={require('../Assets/img/binance.webp')} width={50} alt="Binance" />
                                        <img src={require('../Assets/img/tether.webp')} width={50} alt="Tether" />
                                    </div>
                                    <h3 className='step-title'>{t('step3Title', { tokenSymbol: TOKEN_SYMBOL })}</h3>
                                    <p>{t('step3Description', { tokenSymbol: TOKEN_SYMBOL })}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Buy