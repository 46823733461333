import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationAR from './locales/ar/translation.json';
import translationCN from './locales/cn/translation.json';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationNL from './locales/nl/translation.json';
import translationCZ from './locales/cz/translation.json';
import translationDE from './locales/de/translation.json';
import translationHU from './locales/hu/translation.json';
import translationID from './locales/id/translation.json';
import translationIT from './locales/it/translation.json';
import translationJP from './locales/jp/translation.json';
import translationKR from './locales/kr/translation.json';
import translationPL from './locales/pl/translation.json';
import translationPT from './locales/pt/translation.json';
import translationRO from './locales/ro/translation.json';
import translationRU from './locales/ru/translation.json';
import translationES from './locales/es/translation.json';
import translationTR from './locales/tr/translation.json';

// The translations
const resources = {
  ar: {
    translation: translationAR,
  },
  cn: {
    translation: translationCN,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
  cz: {
    translation: translationCZ,
  },
  de: {
    translation: translationDE,
  },
  hu: {
    translation: translationHU,
  },
  id: {
    translation: translationID,
  },
  it: {
    translation: translationIT,
  },
  jp: {
    translation: translationJP,
  },
  kr: {
    translation: translationKR,
  },
  pl: {
    translation: translationPL,
  },
  pt: {
    translation: translationPT,
  },
  ro: {
    translation: translationRO,
  },
  ru: {
    translation: translationRU,
  },
  es: {
    translation: translationES,
  },
  tr: {
    translation: translationTR,
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if the current language is not available
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;
