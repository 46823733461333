import React from 'react';
import { useTranslation } from 'react-i18next';
import pointImg from '../Assets/img/point.svg';

export default function ShoutoutPlan() {
  const { t } = useTranslation();

  return (
    <section id="plan" className="roadmap">
      <div className="container">
        <div className="row">
          <h2 className="text-yellow font-24 font-sm-50 text-center fw-bold pb-sm-5 mb-3">
            {t('shootoutPlanTitle')}
          </h2>
          <div className="col-12 col-lg-4 box second-card">
            <h3 className="font-22 font-sm-36 text-white text-uppercase fw-bold pb-2">
              {t('phase1Title')}
            </h3>
            <ul className="p-0">
              {t('phase1Steps', { returnObjects: true }).map((step, index) => (
                <li key={index} className="text-white font-15 font-sm-17 fe-medium pb-3 d-flex align-items-start fw-medium">
                  <img src={pointImg} alt='point' className="mt-2" />
                  <span className="ms-2">{step}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-lg-4 box">
            <h3 className="font-22 font-sm-36 text-white text-uppercase fw-bold pb-2">
              {t('phase2Title')}
            </h3>
            <ul className="p-0">
              {t('phase2Steps', { returnObjects: true }).map((step, index) => (
                <li key={index} className="text-white font-15 font-sm-17 fe-medium pb-3 d-flex align-items-start fw-medium">
                  <img src={pointImg} alt='point' className="mt-2" />
                  <span className="ms-2">{step}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-lg-4 box">
            <h3 className="font-22 font-sm-36 text-white text-uppercase fw-bold pb-2">
              {t('phase3Title')}
            </h3>
            <ul className="p-0">
              {t('phase3Steps', { returnObjects: true }).map((step, index) => (
                <li key={index} className="text-white font-15 font-sm-17 fe-medium pb-3 d-flex align-items-start fw-medium">
                  <img src={pointImg} alt='point' className="mt-2" />
                  <span className="ms-2">{step}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
