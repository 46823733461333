import { bsc } from "wagmi/chains";
import bnbImg from '../Assets/img/binance-coin.png';
import usdtImg from '../Assets/img/usdt.png';

export const FAQS = [
  {
    question: "faqSection.faqs.0.question",
    answer: "faqSection.faqs.0.answer"
  },
  {
    question: "faqSection.faqs.1.question",
    answer: "faqSection.faqs.1.answer"
  },
  {
    question: "faqSection.faqs.2.question",
    answer: "faqSection.faqs.2.answer"
  },
  {
    question: "faqSection.faqs.3.question",
    answer: "faqSection.faqs.3.answer"
  },
  {
    question: "faqSection.faqs.4.question",
    answer: "faqSection.faqs.4.answer"
  },
  {
    question: "faqSection.faqs.5.question",
    answer: "faqSection.faqs.5.answer"
  },
  {
    question: "faqSection.faqs.6.question",
    answer: "faqSection.faqs.6.answer"
  }
];


export const presaleData = [
  { round: 'Round 1', supply: '40,000,000,000', price: '$0.0000085', total: '$340,000' },
  { round: 'Round 2', supply: '40,000,000,000', price: '$0.000011', total: '$440,000' },
  { round: 'LISTING PRICE ON DEX/CEX', supply: '', price: '$0.0000183', total: '' },
  { round: 'Round 3', supply: '40,000,000,000', price: '$0.000013', total: '$520,000' },
  { round: 'Round 4', supply: '27,000,000,000', price: '$0.000016', total: '$432,000' },
];

export const presaleTotalData = { round: 'Total', supply: '147,000,000,000', price: '', total: '$1,732,000' };



export const DEFAULT_RPC = process.env.REACT_APP_DEFAULT_RPC;
export const DEFAULT_CHAIN = process.env.REACT_APP_DEFAULT_CHAIN;
export const MULTICALL = process.env.REACT_APP_MULTICALL;
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS;
export const EXPLORE_URL = process.env.REACT_APP_EXPLORE_URL;
export const TOKEN_SYMBOL = `$${process.env.REACT_APP_TOKEN_SYMBOL}`;
export const TOKEN_DECIMALS = process.env.REACT_APP_TOKEN_DECIMALS;
export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;
export const TOTAL_SUPPLY = process.env.REACT_APP_TOTAL_SUPPLY;
export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
export const PRESALE_ADDRESS = process.env.REACT_APP_PRESALE_ADDRESS;
export const LISTING_PRICE = `$${process.env.REACT_APP_LISTING_PRICE}`;
export const TOTAL_STAGE = process.env.REACT_APP_TOTAL_STAGE;
export const chains = [bsc]
export const BASE_URL = "https://clashofmemesaipresale.vip"


export const BUY_CURRENCY = [
  {
    address: "0x0000000000000000000000000000000000000000",
    name: "Binanace Coin",
    symbol: "BNB",
    image: bnbImg,
    decimals: 18
  },
  {
    address: "0x55d398326f99059fF775485246999027B3197955",
    name: "Tether USD",
    symbol: "USDT",
    image: usdtImg,
    decimals: 18
  }
]

export const languages = [
  { code: 'en', name: 'English', flag: '/assets/images/flags/en.svg' },
  { code: 'ar', name: 'Arabic', flag: '/assets/images/flags/arab.svg' },
  { code: 'cn', name: 'Chinese', flag: '/assets/images/flags/cn.svg' },
  { code: 'cz', name: 'Czech', flag: '/assets/images/flags/cz.svg' },
  { code: 'nl', name: 'Dutch', flag: '/assets/images/flags/nl.svg' },
  { code: 'fr', name: 'French', flag: '/assets/images/flags/fr.svg' },
  { code: 'de', name: 'German', flag: '/assets/images/flags/de.svg' },
  { code: 'hu', name: 'Hungarian', flag: '/assets/images/flags/hu.svg' },
  { code: 'id', name: 'Indonesian', flag: '/assets/images/flags/id.svg' },
  { code: 'it', name: 'Italian', flag: '/assets/images/flags/it.svg' },
  { code: 'jp', name: 'Japanese', flag: '/assets/images/flags/jp.svg' },
  { code: 'kr', name: 'Korean', flag: '/assets/images/flags/kr.svg' },
  { code: 'pl', name: 'Polish', flag: '/assets/images/flags/pl.svg' },
  { code: 'pt', name: 'Portuguese', flag: '/assets/images/flags/pt.svg' },
  { code: 'ro', name: 'Romanian', flag: '/assets/images/flags/ro.svg' },
  { code: 'ru', name: 'Russian', flag: '/assets/images/flags/ru.svg' },
  { code: 'es', name: 'Spanish', flag: '/assets/images/flags/es.svg' },
  { code: 'tr', name: 'Turkish', flag: '/assets/images/flags/tr.svg' }
];


export const stageTotal = [40000000000,40000000000,40000000000,27000000000]
export const INITAL_RAISED = 305.150
