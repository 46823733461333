import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import binanceImg from '../Assets/img/img1.png';
import coincodexImg from '../Assets/img/img2.png';
import tradingviewImg from '../Assets/img/img3.png';
import cryptonewsImg from '../Assets/img/img4.png';
import thebittimeImg from '../Assets/img/img5.png';
import timesindiaImg from '../Assets/img/img6.svg';
import img7Img from '../Assets/img/img7.svg';

const Featured = () => {
    const images = [
        binanceImg,
        coincodexImg,
        tradingviewImg,
        cryptonewsImg,
        thebittimeImg,
        timesindiaImg,
        img7Img
    ];

    return (
        <section id="featured" className="featured overflow-hidden mt-5">
            <div className="container">
                <div>
                    <h3 className="text-uppercase font-24 fw-bolder font-lg-36 text-yellow text-center">
                        FEATURED IN
                    </h3>
                    <div className="marquee mt-4">
                        <div className="marquee-content">

                            <div className="d-flex justify-items-between align-items-center px-3">
                                {images.map((src, index) => (
                                    <img key={index} alt="" className="img-fluid marquee-item" src={src} />
                                ))}
                                {images.map((src, index) => (
                                    <div className="img-fluid marquee-item" key={`duplicate-${index}`}>
                                        <img alt="" src={src} />
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    <div className="mt-3 d-none">
                        <OwlCarousel className="owl-theme" loop margin={10} nav>
                            {images.map((src, index) => (
                                <div className="item" key={index}>
                                    <img alt="" className="img-fluid mx-auto px-sm-4 px-md-3" src={src} />
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Featured;
