import { getMultiCall, getWeb3Contract } from '../helper/contractHelper';
import { BUY_CURRENCY, PRESALE_ADDRESS, TOKEN_DECIMALS } from '../helper/constant';
import { useEffect, useState } from 'react';
import presaleAbi from '../abis/presale.json';
import tokenAbi from '../abis/token.json';
import { toast } from 'react-toastify';
import { useAccount } from 'wagmi';
import { getWeb3 } from '../helper/functions';



export const useAccountStats = (updator, selectedId = 0) => {
    const { address } = useAccount();
    const web3 = getWeb3()
    const [stats, setStats] = useState({
        tokenBal: 0,
        isApproved: 0,
        claimedOf: 0,
        userAvalibleClaim: 0,
        purchasedOf: 0
    });

    let presaleContract = getWeb3Contract(presaleAbi, PRESALE_ADDRESS);


    useEffect(() => {
        const fetch = async () => {
            try {
                setStats({
                    ...stats,
                    loading: true
                })
                let tokenBal = 0
                let isApproved = 0
                if (BUY_CURRENCY[selectedId].address !== '0x0000000000000000000000000000000000000000') {
                    let tokenContract = getWeb3Contract(tokenAbi, BUY_CURRENCY[selectedId].address);
                    tokenBal = await tokenContract.methods.balanceOf(address).call();
                    tokenBal = parseFloat(tokenBal) / Math.pow(10, BUY_CURRENCY[selectedId].decimals)

                    isApproved = await tokenContract.methods.allowance(address, PRESALE_ADDRESS).call();
                    isApproved = parseFloat(isApproved) / Math.pow(10, BUY_CURRENCY[selectedId].decimals)
                }
                else {
                    tokenBal = await web3.utils.fromWei(await web3.eth.getBalance(address), 'ether')
                    isApproved = 1000000;
                }

                const data = await getMultiCall([
                    presaleContract.methods.claimedOf(address),
                    presaleContract.methods.userAvalibleClaim(address),
                    presaleContract.methods.purchasedOf(address)
                ])

                
                
                setStats({
                    tokenBal,
                    isApproved,
                    claimedOf: data[0] / Math.pow(10, TOKEN_DECIMALS),
                    userAvalibleClaim: data[1] / Math.pow(10, TOKEN_DECIMALS),
                    purchasedOf: data[2] / Math.pow(10, TOKEN_DECIMALS),
                })
            }
            catch (err) {
                console.log(err)
                toast.error(err.reason ? err.reason : err.message);
            }
        }

        if (address) {
            fetch()
        }
        else {
            setStats({
                tokenBal: 0,
                isApproved: 0,
                claimedOf: 0,
                userAvalibleClaim: 0
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updator, address, selectedId])

    return stats;
}


