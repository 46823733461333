import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();
    
    return (
        <>
            <footer className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h5 className='section-title mb-1'>{t('footer.disclaimerTitle')}</h5>
                            <p className="fa-12">{t('footer.disclaimerText')}</p>
                            <p className="fa-14">{t('footer.copyrightText', { year: currentYear })}</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
