import { EXPLORE_URL, LISTING_PRICE, TOKEN_ADDRESS, TOKEN_NAME, TOKEN_SYMBOL, TOTAL_SUPPLY } from '../helper/constant';
import auditbyImg from '../Assets/img/audit-by.svg'
import { trimAddress } from '../helper/functions';


function Tokonomics() {

    return (
        <>
            <div className="token-section" id="token">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 text-center">
                            <h2 className='section-title mb-5'><span>Token Details</span></h2>
                        </div>
                    </div>
                    <div className='align-items-center row d-flex justify-content-center'>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner'>
                                <h5 className="item--title">Token Name</h5>
                                <p className='item-desc'>{TOKEN_NAME}</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner'>
                                <h5 className="item--title">Token Symbol</h5>
                                <p className='item-desc'>{TOKEN_SYMBOL}</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner'>
                                <h5 className="item--title">Total supply</h5>
                                <p className='item-desc'>{TOTAL_SUPPLY}</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Smart Contract</h5>
                                <p className='item-desc'>
                                    <a className='text-white' rel="noreferrer" target='_blank' href={`${EXPLORE_URL}/address/${TOKEN_ADDRESS}`}>
                                        {trimAddress(TOKEN_ADDRESS , 15)}<svg className='mb-2 mx-1' viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Listing price</h5>
                                <p className='item-desc'>{LISTING_PRICE}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Tokonomics