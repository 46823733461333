import React from 'react';
import { useTranslation } from 'react-i18next';
import uitilityImg from '../Assets/img/uility.gif';
import mobuitilityImg from '../Assets/img/mob-uility.gif';

export default function UtilityIdea() {
  const { t } = useTranslation();

  return (
    <div className='container'>
      <div className="stake-content mb-5">
        <div className="col-12 col-lg-11">
          <div className="d-flex flex-column flex-lg-row gap-5 px-3 px-lg-0">
            <img src={uitilityImg} alt="Utility" className="image d-none d-lg-block" style={{ borderRadius: "10px" }} />
            <img src={mobuitilityImg} alt="Mobile Utility" className="image d-lg-none" style={{ borderRadius: "10px" }} />
            <div className="info">
              <div className="mb-4">
                <h3 className="title text-uppercase text-yellow">{t('utilityIdeasTitle')}</h3>
                <p className="desc">{t('utilityIdeasDescription')}</p>
              </div>
              <div className="divider primary mb-4"></div>
              <div className="mb-4">
                <h3 className="title text-uppercase text-yellow">{t('campfireStoriesTitle')}</h3>
                <p className="desc">{t('campfireStoriesDescription')}</p>
              </div>
              <div className="divider primary mb-4"></div>
              <div className="mb-4">
                <h3 className="title text-uppercase text-yellow">{t('tokenGovernanceRoundupsTitle')}</h3>
                <p className="desc">{t('tokenGovernanceRoundupsDescription')}</p>
              </div>
              <div className="divider primary mb-4"></div>
              <div className="mb-4">
                <h3 className="title text-uppercase text-yellow">{t('luckyClashOfMemesLotteriesTitle')}</h3>
                <p className="desc">{t('luckyClashOfMemesLotteriesDescription')}</p>
              </div>
              <div className="divider primary mb-4"></div>
              <div className="mb-4">
                <h3 className="title text-uppercase text-yellow">{t('clashOfMemesStakingTitle')}</h3>
                <p className="desc">{t('clashOfMemesStakingDescription')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
