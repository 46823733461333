import React from "react";
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { trimAddress } from "../helper/functions";

export const ConnectButton = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  return (

    <button type="button" onClick={() => open()} className="btn tab-btn w-100">
      {isConnected && address ? trimAddress(address) : "Connect Wallet"}
    </button>
  );
};