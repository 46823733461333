import logo from "../Assets/img/logo.jpg"
import { ConnectButton } from "./ConnectButton";
import { languages } from '../helper/constant';
import { useState } from "react";
import { useTranslation } from 'react-i18next';


function Header() {
    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
    const { i18n } = useTranslation();
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language.code);
    }

    return (
        <>
            <header className="page-header">
                <nav class="navbar navbar-expand-lg ">
                    <div class="container">
                        <a class="navbar-brand" href="#sec">
                            <img src={logo} className="border-radius-16" alt="logo" width={90} height={90} />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                            <i class="fa-solid fa-bars"></i>
                        </button>

                        <div class="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#home">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#how-buy">How to Buy</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#token">Tokonomics</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#roadmap">Roadmap</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" rel="noreferrer" target='_blank' href="https://www.clashofmemesai.vip/">Clashofmemes</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#whitepaper">Whitepaper</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#faq">Faq's</a>
                                </li>
                                <li class="nav-item">
                                    <ConnectButton />
                                </li>
                                <li class="mt-1 mx-2 nav-item">
                                    <ul class="navbar-nav">
                                        <li class="nav-item dropdown">
                                            <button
                                                className="btn btn-light dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src={selectedLanguage.flag}
                                                    alt={selectedLanguage.name}
                                                    className="flag-icon"
                                                    style={{ width: '20px', height: '15px', marginRight: '8px' }}
                                                />
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                                {languages.map((language) => (
                                                    <li key={language.code}>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#sec"
                                                            onClick={() => handleLanguageChange(language)}
                                                        >
                                                            <img
                                                                src={language.flag}
                                                                alt={language.name}
                                                                className="flag-icon"
                                                                style={{ width: '20px', height: '15px', marginRight: '8px' }}
                                                            />
                                                            {language.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                            </ul>

                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
export default Header;