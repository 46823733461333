import React from 'react'
import Buy from '../Components/Buy';
import Tokonomics from '..//Components/Tokenomics';
import Presale from '../Components/Presale';
import Whitepaper from '../Components/Whitepaper';
import Faq from '../Components/Faq';
import HeroSection from '../Components/HeroSection';
import UtilityIdea from '../Components/UtilityIdea';
import ShoutoutPlan from '../Components/ShoutoutPlan';
import Tokenomics2 from '../Components/Tokenomics2';
import Gallery from '../Components/Gallery';
import Featured from '../Components/Featured';
import MultipleExchange from '../Components/MultipleExchange';

export default function Home() {
    return (
        <>
            <HeroSection />
            <Featured/>
            <Buy />
            <Tokonomics />
            <UtilityIdea/>
            <ShoutoutPlan/>
            <Tokenomics2/>
            <Gallery/>
            <MultipleExchange/>
            {/* <Roadmap /> */}
            <Presale />
            <Whitepaper />
            <Faq />
        </>
    )
}
