import React from 'react';
import { useTranslation } from 'react-i18next';
import perAmountImg from '../Assets/img/per-amount.svg';

export default function Tokenomics2() {
  const { t } = useTranslation();

  const data = t('sections', { returnObjects: true });

  return (
    <section id="tokenomics" className="tokenomics">
      <div className="container">
        <h1 className="font-50 text-yellow text-uppercase fw-bold text-center pb-5">
          {t('tokenomicsTitle')}
        </h1>
        <div className="row box-wrap">
          {data.map((item, index) => (
            <div key={index} className="col-12 col-lg-2 text-center mb-3">
              <div className="position-relative">
                <img src={perAmountImg} alt="per-amount" height="160" width="160" />
                <span className="font-40 fw-bold text">{item.percentage}</span>
              </div>
              <h2 className="font-18 text-uppercase fw-bold text-white pb-2 pt-4">{item.title}</h2>
              <p className="font-16 fw-bold text-white m-0">{item.tokens}</p>
              <span className="font-18 fw-bold text-white">Tokens</span>
            </div>
          ))}
          {data.map((item, index) => (
            <div key={index} className="col-12 col-lg-6 mt-4 d-none d-sm-block">
              <h2 className="font-27 fw-semibold text-white">{item.title}</h2>
              <p className="font-18 text-white m-0">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
